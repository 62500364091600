import { createBrowserRouter, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { ErrorPage } from '@modules/ErrorPage';
import { MainPage } from '@modules/MainPage';
import { TechnicPage } from '@modules/TechnicPage';
import { AlgopackPage } from '@modules/Algopack/AlgopackPage';
import { InvestorPro } from '@modules/Investorpro';
import { ScreenersPage } from '@modules/Investorpro/modules/ScreenersPage';
import { CartPage } from '@modules/CartPage';
import { PersonalAccount } from '@modules/PersonalAccount/PersonalAccountPage';
import { PriceCenter } from '@modules/PriceCenter';
import { ProPack } from '@modules/ProPack';
import { PaymentInfoPage } from '@modules/PaymentInfoPage';
import { ProPackLanding } from '@modules/ProPackLanding';
import { MarketDive } from '@modules/MarketDive';
import { ComplianceToolLanding } from '@modules/ComplianceToolLanding';
import { PriceCenterProduct } from '@modules/PriceCenterProduct';
import { LandingPage } from '@modules/Investorpro/modules/LandingPage';
import { CasePage } from '@modules/Investorpro/modules/CasePage';
import { Layout } from '@libs/components';
import { SessionExpiredPage } from '@modules/SessionExpiredPage';
import { useAuthorize } from '@libs/utils';
import { CKI } from '@modules/CKI';
import { SessionEmailExpiredPage } from '@modules/PersonalAccount/SessionEmailExpiredPage';
import { LinkNotValidPage } from '@modules/PersonalAccount/LinkNotValidPage';
import { HelpCenter } from '@modules/HelpCenter/HelpCenterPage';
import { ArticleListPage } from '@modules/Articles/ArticleListPage';
import { ArticlePage } from '@modules/Articles/ArticlePage';
import { useEffect } from 'react';
import { ReferencePage } from '@modules/HelpCenter/ReferencePage';
import { SearchMainPage } from '@modules/SearchPage';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@store/store';
import { getProductListThunk } from '@store/store/thunk/product.thunk';
import { bodyReq } from '@libs/components/layout/Layout';

const ProtectedRoute = ({ element }: { element: JSX.Element }) => {
    const { isAuthorized } = useAuthorize();
    const [searchParams] = useSearchParams();

    return isAuthorized ||
        (searchParams.get('isInvestorproRedirect') && searchParams.get('isInvestorProAuthorized'))
? (
        element
    )
: (
        <Navigate to={'/'} />
    );
};

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const path = localStorage.getItem('preLoginPath') || '';

        if (!path.includes('checkemail')) {
            localStorage.removeItem('preLoginPath');
        }
        navigate(path);
    }, []);

    return <></>;
};

export const AppRouter = () => {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getProductListThunk(bodyReq));
    }, []);

    const productList = useSelector((state: RootState) => state.product.productList);

    const dynamicPathByRequest = (key: string): string => {
        const productsURLS = productList.filter((item) => item.status !== 'ARCHIVED').map((el) => el.landingPage);

        return productsURLS.find((el) => el.includes(key)) ?? '';
    };

    const router = createBrowserRouter([
        {
            path: '/',
            element: <Layout />,
            errorElement: <ErrorPage />,
            children: [
                {
                    path: '/',
                    element: <MainPage />,
                },
                {
                    path: 'tech-page',
                    element: <TechnicPage />,
                },
                // {
                //     path: 'products',
                //     children: [
                //         // {
                //         //     path: '',
                //         //     element: <ProductsPage />,
                //         // },
                {
                    path: dynamicPathByRequest('algopack'),
                    element: <AlgopackPage />,
                },
                {
                    path: dynamicPathByRequest('propack'),
                    children: [
                        { index: true, element: <ProPackLanding /> },
                        {
                            path: 'examples',
                            element: <ProPack />,
                        },
                    ],
                },
                {
                    path: dynamicPathByRequest('investorpro'),
                    element: <InvestorPro />,
                    children: [
                        {
                            path: '',
                            element: <LandingPage />,
                        },
                        {
                            path: 'screeners',
                            element: <ScreenersPage />,
                        },
                    ],
                },
                {
                    path: dynamicPathByRequest('investorpro') + '/case',
                    element: <CasePage />,
                },
                {
                    path: dynamicPathByRequest('pricecenter'),
                    children: [
                        { index: true, element: <PriceCenter /> },
                        {
                            path: 'product',
                            element: <PriceCenterProduct />,
                        },
                    ],
                },
                {
                    path: dynamicPathByRequest('cki'),
                    element: <CKI />,
                },
                // {
                //     path: 'about',
                //     children: [
                //         {
                //             path: 'algopack',
                //             element: <AboutAlgopackPage />,
                //         },
                //     ],
                // },
                {
                    path: dynamicPathByRequest('compliencetool'),
                    element: <ComplianceToolLanding />,
                },
                {
                    path: dynamicPathByRequest('marketdive'),
                    element: <MarketDive />,
                },
                //     ],
                // },
                {
                    path: 'help-center',
                    children: [
                        {
                            path: '',
                            element: <HelpCenter />,
                        },
                        {
                            path: 'references/:referenceId/article/:articleId',
                            element: <ReferencePage />,
                        },
                    ],
                },
                {
                    path: 'search-page',
                    element: <SearchMainPage />,
                },
                {
                    path: 'personal-account',
                    children: [
                        {
                            path: '',
                            element: <ProtectedRoute element={<PersonalAccount />} />,
                        },
                        {
                            path: 'session-expired',
                            element: <SessionEmailExpiredPage />,
                        },
                        {
                            path: 'not-valid-link',
                            element: <LinkNotValidPage />,
                        },
                    ],
                },
                {
                    path: 'cart',
                    element: <CartPage />,
                },
                {
                    path: 'articles',
                    children: [
                        {
                            path: '',
                            element: <ArticleListPage />,
                        },
                        {
                            path: ':articleId',
                            element: <ArticlePage />,
                        },
                    ],
                },
                {
                    path: 'payment-info/:status',
                    element: <PaymentInfoPage />,
                },
                { path: 'session-expired', element: <SessionExpiredPage /> },
                { path: 'logout', element: <Logout /> },
            ],
        },
    ]);

    return router;
};
