import { IPriceCenterStateCharts } from '@store/store/slices/pricecenter.slice';
import {
    PriceCenterChart,
    PriceCenterBond,
    PriceCenterValuation,
    PriceCenterChartPoint,
} from '@store/store/thunk/pricecenter/Models';
import { Tick, Chart } from 'chart.js';
import { parse } from 'date-fns';
import { getDateTransformed } from '../utils';

export const months = [
    new Date('2024-01-02'),
    new Date('2024-02-01'),
    new Date('2024-03-01'),
    new Date('2024-04-01'),
    new Date('2024-05-01'),
    new Date('2024-06-01'),
    new Date('2024-07-01'),
    new Date('2024-08-01'),
    new Date('2024-09-01'),
    new Date('2024-10-01'),
    new Date('2024-11-01'),
    new Date('2024-12-01'),
];

export const priceCenterMarketColors = ['#7348ED', '#48A31A', '#2380EB', '#EBC713'];

export const priceCenterPriceProfitColor = [
    '#48A31A',
    '#2380EB',
    '#EBC713',
    '#0090FF',
    '#8EDC1F',
    '#8B52FF',
    '#EDD567',
    '#1A51C7',
];

export const getBorderColor = (type?: string) => {
    switch (type) {
        case 'ЕвроГос':
        case 'Гос': {
            return priceCenterPriceProfitColor[0];
        }
        case 'ЕвроКорп':
        case 'Корп': {
            return priceCenterPriceProfitColor[1];
        }
        case 'Муни': {
            return priceCenterPriceProfitColor[2];
        }
        default:
    }
};

export const getPriceProfitCheckboxColor = (data: any) => {
    const c = data.chartCode;

    if (c === 'RUCBTRNS' || c === 'CORP_BONDS_CHART') {
        return priceCenterPriceProfitColor[1];
    } else if (c === 'RUMBTRNS' || c === 'LOCAL_BONDS_CHART') {
        return priceCenterPriceProfitColor[2];
    } else if (c === 'RGBI' || c === 'GOV_BONDS_CHART') {
        return priceCenterPriceProfitColor[0];
    } else {
        return getBorderColor(data.type);
    }
};

export const getXAxisDatesForPriceProfitChart = (charts: IPriceCenterStateCharts) => {
    let maxDate = 0;
    let minDate = new Date().getTime();

    const result: Date[] = [];

    for (let i = 1; i <= 3; i++) {
        const chart = charts.data.priceCharts[i];
        const currentDateMax = chart?.data[chart.data.length - 1]?.date;
        const currentDateMin = chart?.data[0]?.date;

        if (new Date(currentDateMax).getTime() > maxDate) {
            maxDate = new Date(currentDateMax).getTime();
        }

        if (new Date(currentDateMin).getTime() < minDate) {
            minDate = new Date(currentDateMin).getTime();
        }
    }

    const convert = (value: number) => new Date(value);
    const addMonth = (value: string | number | Date) => {
        const d = new Date(value);
        d.setMonth(d.getMonth() + 1);
        return d.getTime();
    };

    while (minDate <= maxDate) {
        result.push(convert(minDate));
        minDate = addMonth(minDate);
    }

    result.push(convert(maxDate));

    return result;
};

export const getShortMonthLabels = (value: number, index: number, ticks: Tick[]) => {
    const last = ticks[ticks.length - 1];
    const isLastTick = index === ticks.length - 1;
    const getShortMonth = (date: number) => {
        return new Date(date).toLocaleString('default', { month: 'long' }).slice(0, 3);
    };

    const shortMonth = getShortMonth(value);

    if (shortMonth === getShortMonth(last.value)) {
        if (isLastTick) {
            return null;
        } else {
            return shortMonth;
        }
    } else {
        return shortMonth;
    }
};

export const getProfitCurveGradient = (context: any) => {
    const { chart } = context;
    const { ctx, chartArea } = chart;

    if (!chartArea) {
        // This case happens on initial chart load
        return;
    }
    const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(1, 'rgba(115, 72, 237, 0.4)');
    gradient.addColorStop(0.5, 'rgba(115, 72, 237, 0.2)');
    gradient.addColorStop(0, 'rgba(0, 0, 0, 0)');

    return gradient;
};

export const mapToMarketDatasets = (charts: Array<PriceCenterChart<PriceCenterBond[]>>): object[] => {
    const datasets: object[] = [];

    for (let index = 0; index < charts.length; index++) {
        const chartData = charts[index];

        if (index === 0) {
            // кривая доходности
            const newChart = {
                type: 'scatter',
                showLine: true,
                tension: 0.4,
                data: [] as any,
                pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                pointBorderColor: 'rgba(0, 0, 0, 0)',
                backgroundColor: getProfitCurveGradient,
                borderColor: priceCenterMarketColors[index],
                borderWidth: 2,
                fill: true,
            };

            if (chartData.visible) {
                for (const bond of chartData?.data ?? []) {
                    newChart.data.push({
                        x: bond.valDate,
                        y: bond.value,
                    });
                }
            }
            datasets.push(newChart);
        } else {
            // точки
            const newChart = {
                type: 'scatter',
                data: [] as any,
                backgroundColor: priceCenterMarketColors[index],
                pointRadius: 4,
                borderColor: 'white',
                hoverBorderColor: 'white',
                borderWidth: 1,
            };

            if (chartData.visible) {
                for (const bond of chartData?.data ?? []) {
                    newChart.data.push({
                        x: bond.valDate,
                        y: bond.value,
                    });
                }
            }
            datasets.push(newChart);
        }
    }

    return datasets;
};

export const mapToPriceDatasets = (charts: Array<PriceCenterChart<PriceCenterValuation[]>>): object[] => {
    const datasets: object[] = [];

    for (let index = 0; index < charts.length; index++) {
        const chartData = charts[index];

        const newChart = {
            type: 'scatter',
            showLine: true,
            tension: 0.1,
            data: [] as any,
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            borderColor: getPriceProfitCheckboxColor(chartData),
            borderWidth: 1,
        };

        if (chartData.visible) {
            for (const bond of chartData?.data ?? []) {
                newChart.data.push({
                    x: parse(bond.date, 'yyyy-MM-dd', new Date()),
                    y: bond.value,
                });
            }
        }
        datasets.push(newChart);
    }

    return datasets;
};

export const SEPARATED_DECIMAL_2_FORMAT = new Intl.NumberFormat('ru-RU', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
});

// chart plugins

export const handleChartZoom = ({ chart, value, axis }: { chart: Chart; value: [number, number]; axis: string }) => {
    const initMin = chart.getInitialScaleBounds()[axis].min ?? 1;
    const initMax = chart.getInitialScaleBounds()[axis].max ?? 0;
    const onePercent = (initMax - initMin) / 100;

    chart.zoomScale(
        axis,
        {
            min: initMin + onePercent * value[0],
            max: initMin + onePercent * value[1],
        },
        'default',
    );
};

export const handleDrawTooltips = (
    chartInstance: any,
    points: PriceCenterChartPoint<any>[],
    groupChartCode: string,
) => {
    return points.forEach((point: any) => {
        const meta = chartInstance.getDatasetMeta(point.datasetIndex);
        const coordinates = meta?.data[point.dataPointIndex]?.getCenterPoint();

        const id = `${groupChartCode}-tooltip-${point.code}-${point.dataPointIndex}`;
        const tooltip = document.getElementById(id);

        if (coordinates && tooltip) {
            const tooltipMaxWidth = tooltip?.clientWidth - 20;
            const tooltipMaxHeight = 37;

            const getXcoord =
                chartInstance.chartArea.right - coordinates.x > tooltipMaxWidth
                    ? coordinates.x
                    : coordinates.x - tooltipMaxWidth;
            const getYcoord =
                coordinates.y - chartInstance.chartArea.top < tooltipMaxHeight ? coordinates.y + 8 : coordinates.y - 43;

            if (tooltip) {
                tooltip.style.opacity = '1';
                tooltip.style.top = getYcoord + 'px';
                tooltip.style.left = getXcoord + 'px';
            }
        } else {
            if (tooltip) {
                tooltip.style.opacity = '0';
            }
        }
    });
};

export const drawTitles = (titles: { top: string; bottom: string }) => ({
    beforeDraw: function (chart: Chart) {
        const { ctx } = chart;
        ctx.restore();
        const fontSize = 11;
        ctx.font = fontSize + 'px sans-serif';
        ctx.textBaseline = 'middle';

        const top = titles?.top;
        const measure = ctx.measureText(top).width;
        const textX = chart.chartArea.right - 10 - measure;
        const textY = chart.chartArea.top + 10;

        ctx.fillText(top, textX, textY);

        const bot = titles?.bottom;
        const botX = chart.chartArea.left + 10;
        const botY = chart.chartArea.bottom - 10;
        ctx.fillText(bot, botX, botY);

        ctx.save();
    },
});

export const drawLine = {
    afterDraw: (chart: any) => {
        if (chart) {
            const { tooltip } = chart;
            const { ctx } = chart;

            if (!ctx) return;

            type Point = { x: number; y: number; color: string; date: string };

            const points: Point[] = tooltip.dataPoints?.map((point: any) => ({
                x: point.element.x,
                y: point.element.y,
                color: point.dataset.borderColor,
                date: getDateTransformed(point.raw.x, 'tooltip'),
            }));

            let highestIndex = 0;
            let highestYIndex = 0;

            for (let i = 0; i < points?.length; i++) {
                if (points[i]?.y < points[highestIndex]?.y) {
                    highestIndex = i;
                }
            }

            for (let i = 0; i < points?.length; i++) {
                if (points[i]?.x < points[highestIndex]?.x) {
                    highestYIndex = i;
                }
            }

            ctx.lineWidth = 1;
            ctx.strokeStyle = '#2380EB';
            const x = points ? points[highestIndex].x : 0;
            const y = points ? points[highestYIndex].y : 0;

            ctx.save();
            ctx.beginPath();
            ctx.setLineDash([6, 6]);

            ctx.moveTo(x, chart.chartArea.top);
            ctx.lineTo(x, chart.chartArea.bottom);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(chart.chartArea.left, y);
            ctx.lineTo(chart.chartArea.right, y);
            ctx.stroke();
            ctx.setLineDash([]);
            const pointRadius = 4;

            points?.forEach((point) => {
                ctx.beginPath();
                const { x, y, color } = point;
                const strokeWidth = 2;
                ctx.lineWidth = strokeWidth;
                ctx.arc(x, y, pointRadius, 0, 2 * Math.PI);
                ctx.strokeStyle = color;
                ctx.stroke();
                ctx.fillStyle = 'transparent';
                ctx.fill();
            });

            points?.forEach((point) => {
                ctx.beginPath();
                const { x, y, color, date } = point;

                const rightAligned = chart.chartArea.right - x < 70 ? 70 : x < 70 ? 0 : 30;

                const strokeWidth = 2;
                ctx.lineWidth = strokeWidth;

                const b = chart.chartArea.bottom;
                ctx.fillStyle = '#2380EB';
                ctx.strokeStyle = '#2380EB';
                ctx.stroke();
                ctx.rect(x - rightAligned, b, 70, 18);
                ctx.fill();
                ctx.stroke();
                ctx.restore();

                const fontSize = 11;
                ctx.font = fontSize + 'px sans-serif';
                ctx.fontWeight = 400;
                ctx.fillStyle = 'white';
                ctx.textBaseline = 'middle';

                ctx.fillText(date, x + 2 - rightAligned, b + 10);
            });

            ctx.restore();
        }
    },
};
