import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { type RootState } from '@store/store';
import { ProductCard } from '@libs/components';
import { useLocation } from 'react-router-dom';
import { scrollElementByRef } from '@libs/utils';

import styles from './styles.module.scss';

export const Products = () => {
    const location = useLocation();
    const productList = useSelector((state: RootState) => state.product.productList);
    const productsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        location.state = { ...location.state, productsRef };

        const timer = setTimeout(() => {
            if (location.state.goToProducts) scrollElementByRef(productsRef);
            location.state = { ...location.state, goToProducts: false };
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    return (
        <section ref={productsRef} className={classNames(styles.productsWrapper)}>
            <h3>DATA ПРОДУКТЫ</h3>
            <div className={styles.cardWrapper}>
                {productList
                    ?.filter((item) => item.status !== 'ARCHIVED')
                    .map((data) => {
                        return <ProductCard key={data.id} data={data} />;
                    })}
            </div>
        </section>
    );
};
