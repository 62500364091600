import { Button } from 'primereact/button';
import { ReactComponent as StarIcon } from '@shared/images/svg/star.svg';
import { ReactComponent as PlayWhiteIcon } from '@shared/images/svg/playWhite.svg';
import { type RefObjectType } from '@libs/types';
import { scrollElementByRef, useAuthorize, useModal } from '@libs/utils';
import { useSelector } from 'react-redux';
import { type RootState } from '@store/store';
import { useConfig } from '@dynamic-env/useConfig';
import { IS_INVESTORPRO_REDIRECT_PARAMETER } from '@libs/utils/hooks/useAuthorize';
import { InfoModal } from '@modules/Investorpro/shared/components/InfoModal';

import styles from './styles.module.scss';

type Props = {
    refs: RefObjectType<HTMLDivElement>;
};

export const Footer = ({ refs }: Props) => {
    const { isAuthorized } = useAuthorize();
    const { config } = useConfig();
    const { closeModal, openModal, isOpen } = useModal();

    const activeTariff = useSelector((state: RootState) => state.investorPro.activeTariff);

    const handleButtonClick = (refName: string) => {
        const ref = refs[refName];

        if (ref && ref.current) {
            scrollElementByRef(ref);
        }
    };

    return (
        <section className={styles.footerWrapper}>
            <h2>Для высоких доходностей нужны высокие технологии</h2>
            {isAuthorized && !!activeTariff
? (
                <Button
                    label={'Открыть'}
                    icon={<PlayWhiteIcon />}
                    onClick={() => (window.location.href =
                            `${window._env_.REACT_APP_INVESTOR_PRO_LINK}?${IS_INVESTORPRO_REDIRECT_PARAMETER}` || '')}
                />
            )
: (
                <Button
                    className={'flex align-items-end'}
                    icon={<StarIcon />}
                    label={'Получить доступ'}
                    onClick={openModal}
                />
            )}
            <InfoModal isOpen={isOpen} closeModal={closeModal} />
        </section>
    );
};
