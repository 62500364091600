import { DynamicConfig } from "@dynamic-env/config";
import { ProductMnemoCodes } from "@libs/components/misc/ProductCard/types";
import { RootState } from "@store/store";
import { useSelector } from "react-redux";

export const useRoutes = (isProductAppRoute?: boolean) => {
    const productList = useSelector((state: RootState) => state.product.productList);

    const priceCenterRoute = () => {
        if (isProductAppRoute) {
            return productList.find((el) => el.mnemoCode === ProductMnemoCodes.TPC_CENOVOI_CENTR)?.landingPage + '/product?tab=interactiveMap' ?? '/products/priceCenter/product?tab=interactiveMap';
        } else {
            return productList.find((el) => el.mnemoCode === ProductMnemoCodes.TPC_CENOVOI_CENTR)?.landingPage ?? '/products/priceCenter';
        }
    }
    const productRoute = (config: DynamicConfig): Record<string, string> => ({
        [ProductMnemoCodes.TPC_ALGO_PACK]:
            productList.find((el) => el.mnemoCode === ProductMnemoCodes.TPC_ALGO_PACK)?.landingPage ??
            '/products/algopack',
        [ProductMnemoCodes.MOEX_DATA]: window._env_.REACT_APP_MOEXDATA_LINK || '',
        [ProductMnemoCodes.INVESTOR_PRO]:
            productList.find((el) => el.mnemoCode === ProductMnemoCodes.INVESTOR_PRO)?.landingPage ??
            '/products/investorpro',
        [ProductMnemoCodes.MARKET_DIVE]:
            productList.find((el) => el.mnemoCode === ProductMnemoCodes.MARKET_DIVE)?.landingPage ??
            '/products/marketDive',
        [ProductMnemoCodes.COMPLIENCE_TOOL]:
            productList.find((el) => el.mnemoCode === ProductMnemoCodes.COMPLIENCE_TOOL)?.landingPage ??
            '/products/complianceTool',
        [ProductMnemoCodes.TPC_CENOVOI_CENTR]: priceCenterRoute(),
        [ProductMnemoCodes.TPC_PRO_PACK]:
            productList.find((el) => el.mnemoCode === ProductMnemoCodes.TPC_PRO_PACK)?.landingPage ??
            '/products/propack',
        [ProductMnemoCodes.CKI]:
            productList.find((el) => el.mnemoCode === ProductMnemoCodes.CKI)?.landingPage ?? '/products/cki',
        [ProductMnemoCodes.TRADE_RADAR]: window._env_.REACT_APP_TRADE_RADAR || '',
    });

    return productRoute;
}
