import classNames from 'classnames';
import { ReactComponent as ArrowLeft } from '@shared/images/svg/ArrowLeft.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@store/store';
import { getProductByGuidThunk } from '@store/store/thunk/product.thunk';
import { unwrapResult } from '@reduxjs/toolkit';
import { downloadFileThunk } from '@store/store/thunk/attachment.thunk';
import { getTariffsThunk } from '@store/store/thunk/tariff.thunk';
import { type Product } from '@libs/types';
import { getSubscriptionByProductGuidThunk, updateSubscriptionThunk } from '@store/store/thunk/user/subscription.thunk';
import { type SubscriptionData } from '@libs/types/subscription.type';
import { format, differenceInCalendarDays, differenceInHours } from 'date-fns';
import { Button } from 'primereact/button';
import { useModal, useResize } from '@libs/utils';
import { ModalWindow } from '@libs/components';
import { CancelProductModal } from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/components/ManageProductBlock/CancelProductModal';
import { InfoModal } from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/components/ManageProductBlock/InfoModal';
import { RenewalModal } from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/components/ManageProductBlock/RenewalModal';
import { tariffBodyReq } from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/data';
import {
    convertDateToMSK,
    getPeriodName,
    getTariffName,
    updateDateToMidnight,
} from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/components/ManageProductBlock/utils';
import { ErrorModal } from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/components/ManageProductBlock/ErrorModal';
import { hideLoader } from '@store/store/slices/loading.slice';
import { getSavedCardsThunk } from '@store/store/thunk/card.thunk';

import styles from './styles.module.scss';

export const ManageProductBlock = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [searchParams] = useSearchParams();
    const productGuid = searchParams.get('productGuid')!;
    const { isScreenSm } = useResize();
    const datashopTariffs = useSelector((state: RootState) => state.tariff.datashopTariffs);
    const savedCards = useSelector((state: RootState) => state.card.savedCards);

    const [showData, setShowData] = useState<boolean>(false);
    const [product, setProduct] = useState<Product>();
    const [logo, setLogo] = useState<string>();
    const [tariffName, setTariffName] = useState<string>();
    const [period, setPeriod] = useState<string>();
    const [dayLeft, setDayLeft] = useState<number>(0);
    const [hourLeft, setHourLeft] = useState<number>(0);
    const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionData>();

    const [renewalTariffName, setRenewalTariffName] = useState<string>();
    const [renewalPeriod, setRenewalPeriod] = useState<string>();

    const { isOpen: isOpenCancelModal, openModal: openCancelModal, closeModal: closeCancelModal } = useModal();
    const { isOpen: isOpenInfoModal, openModal: openInfoModal, closeModal: closeInfoModal } = useModal();
    const { isOpen: isOpenErrorModal, openModal: openErrorModal, closeModal: closeErrorModal } = useModal();
    const { isOpen: isOpenRenewalModal, openModal: openRenewalModal, closeModal: closeRenewalModal } = useModal();

    useEffect(() => {
        dispatch(getSavedCardsThunk());

        dispatch(getSubscriptionByProductGuidThunk(productGuid)).then((res) => {
            setSubscriptionInfo(unwrapResult(res));
            dispatch(getTariffsThunk({ productGuid, ...tariffBodyReq, statusIn: ['ACTIVE', 'ARCHIVED'] }));
        });

        dispatch(getProductByGuidThunk(productGuid)).then((res) => {
            const product = unwrapResult(res);
            setProduct(product);

            if (product.cartImage) {
                dispatch(downloadFileThunk({ bucket: product.cartImage.bucket, ticket: product.cartImage.fileId }))
                    .then((resp) => {
                        const result = unwrapResult(resp);
                        setLogo(result);
                    })
                    .catch((e) => console.error(e));
            }
        });
    }, []);

    useEffect(() => {
        const dateLeft = updateDateToMidnight(subscriptionInfo?.endDate);
        const dateRight = new Date().toISOString();
        const days = differenceInCalendarDays(dateLeft, dateRight);
        setDayLeft(days);

        const hours = differenceInHours(dateLeft, dateRight);
        setHourLeft(hours);

        const tariffGuid = searchParams.get('tariffGuid')!;

        setTariffName(getTariffName(datashopTariffs, tariffGuid));
        setPeriod(getPeriodName(datashopTariffs, tariffGuid, subscriptionInfo?.tariffParams[0]?.valueId || ''));

        if (subscriptionInfo?.renewalTerms) {
            setRenewalTariffName(getTariffName(datashopTariffs, subscriptionInfo.renewalTerms.tariffGuid));

            setRenewalPeriod(
                getPeriodName(
                    datashopTariffs,
                    subscriptionInfo?.renewalTerms?.tariffGuid,
                    subscriptionInfo?.renewalTerms?.tariffParams[0]?.valueId || '',
                ),
            );
        }

        setShowData(true);
    }, [datashopTariffs, subscriptionInfo]);

    const cancelRenewal = () => {
        dispatch(updateSubscriptionThunk({ subscriptionId: subscriptionInfo?.id!, body: {} })).then((res) => {
            const result = unwrapResult(res);
            setSubscriptionInfo(result);
        });
    };

    const onConfirmCancelProduct = () => {
        dispatch(getSubscriptionByProductGuidThunk(productGuid))
            .then((res) => {
                setSubscriptionInfo(unwrapResult(res));
            })
            .then(() => {
                dispatch(hideLoader());
                closeCancelModal();
                openInfoModal();
            });
    };

    const getDayLeftString = () => {
        let result = '';
        const isDay = hourLeft > 23;
        const number = isDay ? dayLeft : hourLeft;

        if (!number || (number % 100 > 10 && number % 100 < 15)) {
            result = isDay ? 'дней' : 'часов';
        } else {
            switch (number % 10) {
                case 1:
                    result = isDay ? 'день' : 'час';
                    break;
                case 2:
                case 3:
                case 4:
                    result = isDay ? 'дня' : 'часа';
                    break;
                default:
                    result = isDay ? 'дней' : 'часов';
            }
        }

        return `${number} ${result}`;
    };

    return showData
? (
        <>
            <div className={classNames(styles.manageProductBlockWrapper, 'flex', 'flex-column')}>
                <div onClick={() => navigate('/personal-account')} className={classNames(styles.returnBlock, 'flex')}>
                    <ArrowLeft />
                    <span>Вернуться</span>
                </div>

                <div className={classNames(styles.productWrapper, 'flex', 'flex-column')}>
                    <div className={styles.logoWrapper}>
                        <img src={logo} />
                    </div>

                    <div className={classNames(styles.contentWrapper, 'flex', 'flex-column')}>
                        <div className={classNames('flex', 'flex-column')}>
                            <p>Действующая подписка</p>

                            <div>
                                <div className={classNames(styles.rowWrapper, 'flex', 'justify-content-between')}>
                                    <span>Тариф</span>
                                    <span>{tariffName}</span>
                                </div>
                                <div className={classNames(styles.rowWrapper, 'flex', 'justify-content-between')}>
                                    <span>Период действия</span>
                                    <span>{period}</span>
                                </div>
                                <div className={classNames(styles.rowWrapper, 'flex', 'justify-content-between')}>
                                    <span>Стоимость</span>
                                    <span className={classNames('flex', 'align-items-baseline')}>
                                        {subscriptionInfo?.cost || '0'}
                                        <span>₽</span>
                                    </span>
                                </div>
                                <div className={classNames(styles.rowWrapper, 'flex', 'justify-content-between')}>
                                    <span>
                                        Оплачено по{' '}
                                        {subscriptionInfo?.endDate
                                            ? format(
                                                  subscriptionInfo?.endDate
                                                      ? new Date(
                                                            subscriptionInfo?.endDate.substring(
                                                                0,
                                                                subscriptionInfo?.endDate.length - 1,
                                                            ),
                                                        )
                                                      : new Date(),
                                                  'dd.MM.yyyy',
                                              )
                                            : '—'}
                                    </span>
                                    <span className={classNames(dayLeft < 3 && styles.redSpan)}>
                                        Осталось {getDayLeftString()}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className={classNames(styles.buttonWrapper, 'flex', isScreenSm && 'flex-column')}>
                            {!subscriptionInfo?.renewalTerms && !subscriptionInfo?.refundId && savedCards.length > 0
? (
                                <Button
                                    label={'Продлить подписку'}
                                    className={styles.redBtn}
                                    onClick={openRenewalModal}
                                />
                            )
: null}
                            {!subscriptionInfo?.refundId && subscriptionInfo?.orderId && (
                                <Button
                                    label={'Отказ от услуги'}
                                    className={styles.whiteBtn}
                                    onClick={openCancelModal}
                                />
                            )}
                        </div>
                    </div>

                    {subscriptionInfo?.renewalTerms && (
                        <div className={classNames(styles.contentWrapper, 'flex', 'flex-column')}>
                            <div className={classNames('flex', 'flex-column')}>
                                <p>Условия на следующий период</p>

                                <div>
                                    <div className={classNames(styles.rowWrapper, 'flex', 'justify-content-between')}>
                                        <span>Тариф</span>
                                        <span>{renewalTariffName}</span>
                                    </div>
                                    <div className={classNames(styles.rowWrapper, 'flex', 'justify-content-between')}>
                                        <span>Период действия</span>
                                        <span>{renewalPeriod}</span>
                                    </div>
                                    <div className={classNames(styles.rowWrapper, 'flex', 'justify-content-between')}>
                                        <span>Автопродление</span>
                                        <span>
                                            {subscriptionInfo?.renewalTerms && subscriptionInfo?.renewalTerms.renewal
                                                ? 'Включено'
                                                : 'Выключено'}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className={classNames(styles.buttonWrapper, 'flex', isScreenSm && 'flex-column')}>
                                <Button
                                    label={'Изменить параметры'}
                                    className={styles.whiteBtn}
                                    onClick={openRenewalModal}
                                />
                                <Button label={'Отменить'} className={styles.whiteBtn} onClick={cancelRenewal} />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <ModalWindow isVisible={isOpenCancelModal} closeModal={closeCancelModal}>
                <CancelProductModal
                    orderId={subscriptionInfo?.orderId || ''}
                    productGuid={subscriptionInfo?.productGuid || ''}
                    productName={product?.name || ''}
                    onClose={closeCancelModal}
                    onConfirm={onConfirmCancelProduct}
                    onError={() => {
                        closeCancelModal();
                        openErrorModal();
                    }}
                    subscriptionId={subscriptionInfo?.id || 0}
                />
            </ModalWindow>
            <ModalWindow isVisible={isOpenInfoModal} closeModal={closeInfoModal}>
                <InfoModal closeModal={closeInfoModal} productName={product?.name || ''} />
            </ModalWindow>
            <ModalWindow isVisible={isOpenErrorModal} closeModal={closeErrorModal}>
                <ErrorModal closeModal={closeErrorModal} />
            </ModalWindow>
            <ModalWindow isVisible={isOpenRenewalModal} closeModal={closeRenewalModal}>
                <RenewalModal
                    isOpen={isOpenRenewalModal}
                    closeModal={closeRenewalModal}
                    productName={product?.name || ''}
                    tariffName={tariffName}
                    period={subscriptionInfo?.renewalTerms ? renewalPeriod : period}
                    isRenewal={(subscriptionInfo?.renewalTerms && subscriptionInfo?.renewalTerms.renewal) || false}
                    subscriptionId={subscriptionInfo?.id || 0}
                    setSubscriptionInfo={setSubscriptionInfo}
                />
            </ModalWindow>
        </>
    )
: null;
};
