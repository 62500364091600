import classNames from 'classnames';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ReactComponent as CopyIcon } from '@shared/images/svg/copy.svg';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '@store/store';
import { updateApiKeyThunk } from '@store/store/thunk/user/subscription.thunk';
import { ReactComponent as QuestionIcon } from '@shared/images/personalAccount/svg/apiKeyQuestion.svg';
import { useConfig } from '@dynamic-env/useConfig';

import styles from './styles.module.scss';

type Props = {
    apiKey: string;
    subscriptionId: number;
};

export const ApiKeyModal = ({ apiKey, subscriptionId }: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const { config } = useConfig();

    const copyText = () => {
        navigator.clipboard.writeText(apiKey);
    };

    const updateApiKey = () => {
        dispatch(updateApiKeyThunk(subscriptionId));
    };

    return (
        <div className={classNames(styles.apiKeyModalWrapper, 'flex', 'flex-column')}>
            <h2>Настройка API Key</h2>

            <div className={classNames(styles.contentWrapper, 'flex', 'flex-column')}>
                <div className={classNames(styles.inputWrapper, 'flex', 'flex-column')}>
                    <label htmlFor="apikey">API Key</label>
                    <InputText id="apikey" value={apiKey} />
                </div>
                <div className={classNames(styles.buttonsWrapper, 'flex')}>
                    <Button
                        icon={<CopyIcon />}
                        label={'Копировать'}
                        className={classNames(styles.blackBtn, 'flex')}
                        onClick={copyText}
                    />
                    <Button label={'Обновить'} className={styles.whiteBtn} onClick={updateApiKey} />
                </div>
            </div>

            <div className={classNames(styles.questionWrapper)}>
                <a
                    href={window._env_.REACT_APP_API_KEY_LINK}
                    className={classNames('flex', 'align-items-center')}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                >
                    <QuestionIcon />
                    <span>Как подключить API Key</span>
                </a>
            </div>
        </div>
    );
};
